import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { initializeApp } from "firebase/app";
import { applyActionCode, getAuth } from "firebase/auth";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";


// Configure Firebase.
const config = require("./.env.json")

const app = initializeApp(config);
const auth = getAuth(app);

// These are the messages that appear throughout the reset password process:
const steps = {
  loading: "Loading...",
  validatingCode: "Validating reset-password code.",
  enterNewPassword: "Please enter a new password.",
  success: "Password is reset. Please try logging in now.",
  emailVerified: "Your email address has been verified. Please re-open the mobile application.",
  emailVerificationError: "There was an error verifying your account. Please contact customer support."
}

// These are the error codes that appear to the user:
export const errorCodes = {
  "none": "",
  "invalidActionCode": "The password-reset code is expired or invalid. Please try to reset your password again.",
  "serverFailed": "Failed to talk to server. Please try again later.",
  "passwordsMismatch": "Please make sure both passwords match",
  "passwordTooShort": "Password is too short. Please make sure it's at least 8 characters long.",
  "passwordAtLeastOneNumber": "Password should have at least one number",
  "passwordAtLeastOneLetter": "Password should contain at least one letter",
  "passwordAtLeastOneSymbol": "Password should contain at least one symbol: [!@#$%^&*-_+=.?~()]"
}

// Password rules defined here.
export function validatePassword(password1, password2) {
  if (password1 !== password2) {
    return errorCodes.passwordsMismatch
  } 
  if (password1.length < 8) {
    return errorCodes.passwordTooShort
  }
  if (password1 && password1.match(/\d/) == null) {
    return errorCodes.passwordAtLeastOneNumber
  }
  if (password1 && password1.match(/[a-zA-z]/) == null) {
    return errorCodes.passwordAtLeastOneLetter
  }
  // eslint-disable-next-line
  if (password1 && password1.match(/[\[\]!@#%$^&*\-_\+\=\.\?~\(\)]/) == null) {
    return errorCodes.passwordAtLeastOneSymbol
  }
  return errorCodes.none
}

export function App() {
  const classes = useStyles();

  // from https://firebase.google.com/docs/auth/custom-email-handler#create_the_email_action_handler_page
  // example https://localhost:3000?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy...&lang=fr 
  
  const [parameters, setParameters] = useState({
    mode: "",
    oobCode: "",
    apiKey: "",
    // continueUrl: "",
    // lang: ""
  });
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [currentStep, setCurrentStep] = useState(steps.loading);
  const [errorCode, setErrorCode] = useState(null);

  // When page loads, we query the query parameters from the URL and pass them to validateResetPasswordCode
  // which then calls Firestore to make sure the oobCode is valid and returns the email to display to the user.
  function validateResetPasswordCode(actionCode) {
    setCurrentStep(steps.validatingCode)

    // call Firestore to verify that the actionCode is valid.
    verifyPasswordResetCode(auth, actionCode).then((email) => {

      setEmail(email)
      setCurrentStep(steps.enterNewPassword)
      // console.log("verifyPasswordResetCode: Email confirmed, asking for new password")

    }).catch((error) => {

      setErrorCode(errorCodes.invalidActionCode)
      // console.log("verifyPasswordResetCode", error)
      
    });

  }

  function validateEmailVerificationCode(actionCode) {
    applyActionCode(auth, actionCode).then((resp) => {
      console.log("applyActionCode", resp)

      // Email address has been verified.
      setCurrentStep(steps.emailVerified)
 
      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch((error) => {
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
      console.log("emailValidationError", error)
      setCurrentStep(steps.emailVerificationError)
    });
  }
  
  // calls validatePassword
  function checkPassword(password1, password2) {

    let passwordErrorCode = validatePassword(password1, password2)
    
    setErrorCode(passwordErrorCode)
    return passwordErrorCode
  }

  // "Reset Password" button pressed
  function handleResetPassword(e)  {
      e.preventDefault();

      const isValid = checkPassword(password, confirmPassword)

      if (isValid !== errorCodes.none) return;

      // call Firestore to reset the password
      confirmPasswordReset(auth, parameters.oobCode, password).then((resp) => {
        setCurrentStep(steps.success)
      }).catch((error) => {
        setErrorCode(errorCodes.serverFailed)
        // console.log("confirmPasswordReset", error)
      });
  }

  function parseUrl() {
    let parser = new URLSearchParams(window.location.search)

    let queryParameters = {}
    for (const key in parameters) {
      queryParameters = {...queryParameters, [key]: parser.get(key) }
    }

    return queryParameters
  }
  
  useEffect(() => {
    let queryParameters = parseUrl()
    console.log("parameters", queryParameters)
    setParameters(queryParameters)
    if (queryParameters.mode === "verifyEmail") {
      validateEmailVerificationCode(queryParameters.oobCode)
    }
    if (queryParameters.mode === "resetPassword") {
      validateResetPasswordCode(queryParameters.oobCode)
    };
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderTitle() {
    switch (currentStep) {
      case steps.emailVerified:
        return "Email Verified"
      case steps.emailVerificationError:
        return "Email Not Verified"
      case steps.loading:
        return "Loading..."
      default: 
        return "Reset Password"
    }
  }

  // Generate the form based on which step we're currently on:
  function renderForm() {
  var form = <div/>
  switch (currentStep) {
    case steps.validatingCode:
      form = 
      (
        <div>
          {errorCode ? null :
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        disabled
        value={email}
        onClick={(()=>setCurrentStep(steps.enterNewPassword))}
        autoFocus
      />}

      <Typography component="h2" variant="body1">
        {errorCode}
        </Typography>
      </div>
      );
      break;
    case steps.enterNewPassword:
      
      form = (
        
        <form className={classes.form} onSubmit={handleResetPassword} noValidate>
          <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        value={email}
        autoComplete="email"
        disabled
        autoFocus
      />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          onChange={e => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password "
          type="password"
          id="confirmPassword"
          onChange={e => setConfirmPassword(e.target.value)}
          autoComplete="current-password"
        />
        <br/>
        <br/>
        <Typography component="h2" variant="body1">
        {errorCode}
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Reset Password
        </Button>
        </form>
        );
      break;
    case steps.loading:
      form = <div/>
      break;
    case steps.success:
      form = <div/>
      break;
    case steps.emailVerified:
      form = <div/>
      break;
    case steps.emailVerificationError:
      form = <div/>
      break;
    default:
      break;
  }
  return form
}


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon 
          
             onClick={(()=>setCurrentStep(steps.success))}
          />
        </Avatar>
        <Typography component="h1" variant="h5">
          {renderTitle()}
        </Typography>
        <br/>
        <Typography component="h2" variant="body1">
          {currentStep}
        </Typography>
        <br/>
         {renderForm()}
      </div>
      <br/>
        <Copyright />
    </Container>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Scout Health
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
